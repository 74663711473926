import axios from "axios";

const actions = {
    async GET_RAZDELS_LIST({commit, getters, state}) {
        try {
            const response = await axios.post('api/goods_types', {
                params: {
                    visible: 1,
                    page_num: 1,
                    limit: 100
                },
                action: 'datalist',
                prefix: 'voc',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                let data = response.data.data;
                commit('SET_RAZDELS_LIST', data);
                if(data.length && state.currentRazdelId === 0){
                    let mainRazdels = getters.GET_MAIN_RAZDELS;
                    let firstCategory = mainRazdels.shift();
                    commit('setCurrentRazdelId', firstCategory.id);
                }
            }
        } catch (e) {
            console.log(e);
        }
    },
    async SYNC_LOCAL_DATA({commit, state}, sync_data = 'all') {
        try {
            let params = {};
            if (sync_data === 'cart') {
                params = {chat_id: state.chatId, tg_username: state.userId, cart: state.cart, mode: 'cart'};
            } else if (sync_data === 'wishlist') {
                params = {chat_id: state.chatId, tg_username: state.userId, wishlist: state.wishlist, mode: 'wishlist'};
            } else {
                params = {chat_id: state.chatId, tg_username: state.userId, cart: state.cart, wishlist: state.wishlist, mode: 'all'};
            }

            const response = await axios.post('api/clients', {
                params: params,
                action: 'sync_local_data',
                prefix: 'obj',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                let cart = [];
                let wishlist = [];
                if (sync_data === 'cart') {
                    cart = response.data.data.cart ? response.data.data.cart : [];
                    commit('updateStateParam', {title: 'cart', value: cart});
                } else if (sync_data === 'wishlist') {
                    wishlist = response.data.data.wishlist ? response.data.data.wishlist : [];
                    commit('updateStateParam', {title: 'wishlist', value: wishlist});
                } else {
                    cart = response.data.data.cart ? response.data.data.cart : [];
                    wishlist = response.data.data.wishlist ? response.data.data.wishlist : [];
                    commit('updateStateParam', {title: 'cart', value: cart});
                    commit('updateStateParam', {title: 'wishlist', value: wishlist});
                }
            }
        } catch (e) {
            console.log(e);
        }
    },
    async SYNC_GLOBAL_DATA({commit, state}) {
        try {
            let params = {};
            params = {chat_id: state.chatId, tg_username: state.userId};
            const response = await axios.post('api/clients', {
                params: params,
                action: 'sync_global_data',
                prefix: 'obj',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                let cart = [];
                let wishlist = [];
                cart = response.data.data.cart ? response.data.data.cart : [];
                commit('updateStateParam', {title: 'cart', value: cart});
            }
        } catch (e) {
            console.log(e);
        }
    },
    async GET_APP_INFO({commit}) {
        try {
            const response = await axios.post('api/about', {
                params: {},
                action: 'datainfo',
                prefix: 'obj',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                let data = response.data.data;
                commit('SET_APP_INFO', data);
            }
        } catch (e) {
            console.log(e);
        }
    },
    async CHECK_COOKIE({commit, state}){
      const cookie = await cookieStore.get('cookie')
      commit('setCookie', cookie ? true : false);
    },
    async ACCEPT_COOKIE({commit, state}){
      try{
        let params = {};
        params = {chat_id: state.chatId, tg_username: state.userId};
        await axios.post('api/clients', {
          params: params,
          action: 'accept_cookie',
          prefix: 'obj',
          headers: {
              "Access-Control-Allow-Origin": true
          }
        });
        const day = 24 * 60 * 60 * 1000;

        cookieStore.set({
          name: "cookie",
          value: "true",
          expires: Date.now() + day * 30,
        });
        commit('setCookie', true)
      } catch (e) {
        console.log(e);
      } 
    },

    async getVisibleProducts(){
      try {
        const response = await axios.post("api/goods", {
          params: {
            visible: 1,
          },
          action: "datalist",
          prefix: "obj",
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        });
        if (response.data.success) {
          this.products = response.data.data;
          console.log(this.products);
        }
      } catch (e) {
        console.log(e);
      }
    },

    removeUnavailableGoods({commit, state, dispatch}){
      const availableGoods = this.getVisibleProducts()
      state.cart.forEach(g => {
        const exist = availableGoods.some(i => i.id === g.id)
        if (!exist){
          commit("removeFromCartAtAll", g.id);
          if (state.chatId) {
            dispatch("SYNC_LOCAL_DATA", "cart");
          }
        }
      });
    },

    async checkSaleStatus({ commit, state }, productId) {
      if (window.location.hostname !== 'd2.it-shop-tg.ru' && window.location.hostname !== 'hendlex.it-shop-tg.ru') return
      try{
        const response = await axios.post("api/triggers", {
          params: {
            good_id: productId,
          },
          action: "trigger_info",
          prefix: "obj",
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        });
        if (response.data.success && response.data.data.id) {
          console.log('стейт' + state.saleStatuses)
          commit('SET_SALE_STATUS',   { productId, data: response.data.data }  );
        }
      } catch (e) {
      console.log(e);
      }
    },
};

export default actions;