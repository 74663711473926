export function redirectToProduct(to) {

    let fullRedirectURL = {};

    let start_param = Telegram.WebApp.initDataUnsafe?.start_param;

    if (typeof start_param !== 'undefined' && start_param.indexOf('ref_') === -1) {

        let queryArray = start_param.split('_');
        let prodId = queryArray.length ? queryArray.pop() : '';
        let categoryAlias = queryArray[queryArray.length - 1];

        if (Number(prodId) > 0) {

            fullRedirectURL = {
                id: prodId,
                cat_id: categoryAlias
            };

        }
    }

    return fullRedirectURL
}