<template>
  <button :class="buttonClass">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'my-button',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonClass() {
      return {
        'btn-primary': true,
        'btn-confirm': this.type === 'confirm'
      };
    }
  }
}
</script>

<style scoped>
.btn-primary {
  margin-top: 10px;
}

.btn-confirm {
  background-color: #10b981;
}
</style>
